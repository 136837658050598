import React, { useRef } from 'react';
import { View, StyleSheet, Text, ScrollView, TouchableOpacity } from 'react-native';
import { useWindowDimensions } from 'react-native';
import AUOverview from './Components/analytics/AUOverview';
import ViewsOverview from './Components/analytics/ViewsOverview';

export default function Analytics({ host, appInfo }) {
    const scrollRef = useRef();
    const { width } = useWindowDimensions();
    const isDesktop = width > 800;  // Define breakpoint for desktop

    return (
        <ScrollView style={styles.body} ref={scrollRef}>
            {/* Informational Content */}
            <View style={styles.container}>
                <Text style={styles.HeadingTextTop}>
                    How to see number of successfully sent push notifications?
                </Text>
                <Text style={styles.text}>
                    Go to <TouchableOpacity style={styles.link} onPress={() => window.open("https://expo.dev")}><Text >https://expo.dev</Text></TouchableOpacity>, select Projects, select your project, then select Push Notifications on the left of your screen to see how many push notifications were successfully sent over your selected period of time.
                </Text>
            </View>

            {/* Grid Container */}
            <View style={[styles.splitCont, isDesktop ? styles.row : styles.column]}>
                {/* AUOverview Component */}
                <View style={isDesktop ? styles.halfWidth : styles.fullWidth}>
                    <AUOverview host={host} appInfo={appInfo} />
                </View>

                {/* ViewsOverview Component */}
                <View style={isDesktop ? styles.halfWidth : styles.fullWidth}>
                    <ViewsOverview host={host} appInfo={appInfo} period={'week'} />
                </View>
            </View>
            <View style={styles.fullWidth}>
                <ViewsOverview host={host} appInfo={appInfo} period={'month'} />
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100,
        backgroundColor: '#f9f9f9', // Optional: background color for better contrast
    },
    splitCont: {
        // Base styles for the grid container
        flexDirection: 'column', // Default to column for mobile
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: 'row', // Switch to row for desktop
    },
    column: {
        flexDirection: 'column', // Ensure column direction for mobile
    },
    halfWidth: {
        width: '48%', // Slightly less than half to allow spacing
        marginBottom: 20, // Space between rows on desktop
    },
    fullWidth: {
        width: '100%', // Full width for mobile
        marginBottom: 20, // Space between components
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontSize: 20,
        fontWeight: "700",
        marginBottom: 10,
        color: '#333',
    },
    text: {
        fontFamily: "Arial",
        fontSize: 16,
        color: '#555',
        marginBottom: 10,
    },
    bottomText: {
        fontFamily: "Arial",
        fontSize: 16,
        color: '#555',
        textAlign: 'left',
    },
    linkContainer: {
        backgroundColor: '#1b1f23',
        color: "#fff",
        borderRadius: 6,
        paddingVertical: 15,
        paddingHorizontal: 20,
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 10,
    },
    link: {
        fontFamily: "Arial",
        color: '#3675d4',
        fontSize: 16,
    },
    container: {
        padding: 10,
        paddingHorizontal: 20,
        backgroundColor: '#fff',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#e5e5e5',
        marginBottom: 20,
        gap: 10,
    },
});
