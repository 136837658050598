import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialIcons';

import { LinearGradient } from 'expo-linear-gradient';

export default function HomeHeader({ navigation, setAppInfo, userInfo }) {
    return (
        <View style={styles.headerCont}>
            <View style={styles.smallCont2}>
                <TouchableOpacity 
                    style={styles.smallCont} 
                    onPress={() => {
                        setAppInfo({});
                        navigation.navigate('Dashboard');
                    }}
                >
                    <Image
                        style={styles.logo}
                        source={require('../assets/images/logo.png')}
                        resizeMode='cover'
                    />
                    {screen.width > 700 && (
                        <Text style={styles.logoText}>Dashboard</Text>
                    )}
                </TouchableOpacity>
                {!userInfo.paid_sub && (
                    <TouchableOpacity 
                        style={styles.goPremiumButton} 
                        onPress={() => {
                            setAppInfo({});
                            navigation.navigate('Update');
                        }}
                    >
                        <LinearGradient
                            colors={['#FF512F', '#DD2476']} // Replace with your chosen colors
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 0 }}
                            style={styles.gradient}
                        >
                            <Text style={styles.buttonText}>Upgrade</Text>
                        </LinearGradient>
                    </TouchableOpacity>
                )}
            </View>
            <View style={styles.smallCont2}>
                <Icon
                    name="question-circle"
                    size={23}
                    onPress={() => {
                        navigation.navigate('FAQ');
                    }}
                />
                
                <Icon2
                    name="settings"
                    size={23}
                    onPress={() => {
                        navigation.navigate('Settings');
                    }}
                />

                <TouchableOpacity 
                    style={styles.logoutButton} 
                    onPress={() => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        document.cookie = `AUTH = ""; expires=${d};`;
                        document.cookie = `AUTH = ""; domain=nativenotify.com; expires=${d};`;
                        document.cookie = `AUTH = ""; domain=app.nativenotify.com; expires=${d};`;
                        document.cookie = `app_id = 0; expires=${d};`;
                        document.cookie = `app_id = 0; domain=nativenotify.com; expires=${d};`;
                        document.cookie = `app_id = 0; domain=app.nativenotify.com; expires=${d};`;
                        navigation.navigate('Login');
                    }}
                >
                    <Text style={styles.whiteText}>Logout</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
    
}

const styles = StyleSheet.create({
    headerCont: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        paddingLeft: '5%',
        paddingRight: '5%',
        flexWrap: 'wrap',
        // boxShadow: 'rgb(214 214 214) 0px 2px 5px',
        backgroundColor: '#fafafa',
    },
    smallCont: {
        height: '100%',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center',
    },
    smallCont2: {
        height: '100%',
        flexDirection: 'row',
        gap: 15,
        alignItems: 'center',
    },
    logo: {
        width: 35,
        height: 41
    },
    logoText: {
        fontSize: 16,
        fontFamily: 'Arial',
        fontWeight: 600,
        marginLeft: 5,
    },
    logoutButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#000',
        borderRadius: 3,
    },
    blackText: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400,
    },
    whiteText: {
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 700,
    },
    fingoBoxButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center'
    },
    goPremiumButton: {
        borderRadius: 6,
        overflow: 'hidden', // Ensures the gradient follows the button's shape
        backgroundColor: '#ff9d9d'
    },
    gradient: {
        paddingVertical: 8,
        paddingHorizontal: 15,
        alignItems: 'center',
    },
    buttonText: {
        color: '#FFFFFF',
        fontFamily: 'Arial',
        fontWeight: '700',
        fontSize: 16,
    },
});