import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import axios from 'axios';

import Header from '../../../Headers/HomeHeader';

export default function LoginOrSignUp({ navigation, host }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${host}/api/user`).then(res => {
      if (res.ok) {
        navigation.navigate('Dashboard');
      }
    }).catch(() => {}); // ignore
  }, []);

  const handlePost = () => {
    setLoading(true);

    axios
      .post(`${host}/api/auth/login`, { email, password }, { withCredentials: true })
      .then((res) => {
        navigation.navigate('Dashboard');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(
          'Sorry, that email and password did not match our records. Please try again.'
        );
        setLoading(false);
      });
  };

  return (
    <div style={styles.page} className='radialGradientBackground'>
      <View style={styles.bodyContainer}>
        {/* <RadialGradientBackground style={styles.radialGradient} /> */}
        <Header navigation={navigation} />
        <View style={styles.body}>
          <ScrollView keyboardShouldPersistTaps="handled">
            <TextInput
              style={styles.inputLarge}
              onChangeText={(text) => setEmail(text.toLowerCase())}
              placeholder={'Email'}
              placeholderTextColor={'#6e6d6c'}
              value={email}
            />
            <TextInput
              style={styles.inputLarge}
              onChangeText={(text) => setPassword(text)}
              placeholder={'Password'}
              placeholderTextColor={'#6e6d6c'}
              value={password}
              secureTextEntry={true}
            />
            <View style={styles.loginCont}>
              <TouchableOpacity
                style={styles.buttons}
                onPress={() => handlePost()}
              >
                {loading ? (
                  <Text style={styles.buttonLoading}>
                    <ActivityIndicator size="small" color="rgb(54, 117, 212)" />
                  </Text>
                ) : (
                  <Text style={styles.text}>Login</Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.navigate('ResetPasswordOne')}
              >
                <Text style={styles.textForgot}>Forgot Password?</Text>
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={styles.signUpButton}
              onPress={() => navigation.navigate('SignUpOne')}
            >
              <Text style={styles.text}>Create New Account</Text>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: 'rgb(20, 20, 60)'
  },
  bodyContainer: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
    height: '100%'
  },
  radialGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  body: {
    width: '95%',
    maxWidth: 500,
    maxHeight: 700,
    padding: '4%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 6,
    marginTop: 60,
    alignSelf: 'center',
    // Existing styles...
  },
  buttons: {
    height: 45,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: 6,
    marginBottom: 20,
  },
  inputLarge: {
    width: '100%',
    padding: 12.5,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: '#99999920',
    backgroundColor: '#fff',
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginBottom: 15,
  },
  loginCont: {
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#99999960',
    paddingBottom: 30,
  },
  signUpButton: {
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#06bd43',
    borderRadius: 6,
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30,
  },
  text: {
    fontFamily: 'Arial',
    fontWeight: '700',
    color: 'white',
    fontSize: 20,
  },
  textForgot: {
    fontFamily: 'Arial',
    fontWeight: '600',
    fontSize: 18,
  },
  // Other styles...
});
