import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, ScrollView, Dimensions } from 'react-native';
import axios from '../../../../../axios';
import { LineChart, BarChart } from 'react-native-chart-kit';
import { useWindowDimensions } from 'react-native';

export default function AUOverview({ host, appInfo }) {
    const [usageData, setUsageData] = useState({ tau: 0, mau: 0, wau: 0, dau: 0 });

    const scrollRef = useRef();

    // Fetch usage data
    useEffect(() => {
        async function fetchUsageData() {
            try {
                const response = await axios.get(`${host}/api/active-users/${appInfo.app_id}/${appInfo.app_token}`);
                // console.log("response.data: ", response.data)
                const usageData = {
                    tau: Number(response.data.tau) || 0,
                    mau: Number(response.data.mau) || 0,
                    wau: Number(response.data.wau) || 0,
                    dau: Number(response.data.dau) || 0
                }
                // console.log("usageData: ", usageData);

                if (response.data) {
                    setUsageData(usageData);
                }
            } catch (err) {
                console.log("Error fetching usage data: ", err);
            }
        }
        fetchUsageData();

        // Reset scroll position to top when component mounts
        scrollRef.current?.scrollTo({ y: 0, animated: false });
    }, [host, appInfo]);

    const { width } = useWindowDimensions();
    const isDesktop = width > 800;  // Adjust breakpoint as needed

    // Data for the chart
    const chartData = {
        labels: ["MAU", "WAU", "DAU"],
        datasets: [
            {
                data: [usageData.mau, usageData.wau, usageData.dau],
                color: (opacity = 1) => `rgba(54,117,212, ${opacity})`, // MAU color
                strokeWidth: 3
            }
        ],
        legend: ["Active Users"]
    };

    return (
        <View style={styles.metricsContainer}>
            <Text style={styles.HeadingTextTop}>Active Users Overview</Text>
            <View style={{ marginVertical: 20 }}>
                <BarChart
                    data={chartData}
                    width={isDesktop ? width * 0.35 : width * 0.71} // Adjust width based on device
                    height={220}
                    decimalPlaces={0} // No decimal places on y-axis
                    chartConfig={{
                        backgroundColor: '#fff',
                        backgroundGradientFrom: '#f5f5f5',
                        backgroundGradientTo: '#e6e6e6',
                        color: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                        labelColor: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                        style: {
                            borderRadius: 16,
                            fontFamily: 'Arial'
                        },
                        propsForDots: {
                            r: "6",
                            strokeWidth: "2",
                            stroke: "#3675d2"
                        },
                        decimalPlaces: 0,
                    }}
                    bezier
                    style={{
                        marginVertical: 8,
                        borderRadius: 16,
                        fontFamily: 'Arial'
                    }}
                    fromZero
                />
            </View>

            <View style={styles.smallContainer}>
                <Text style={styles.metricsText}>Total Active Users: {usageData.tau}</Text>
            </View>
            <View style={styles.smallContainer}>
                <Text style={styles.metricsText}>Monthly Active Users: {usageData.mau}</Text>
                <Text style={styles.metricsText}>MAU Rate: {usageData.tau > 0 ? ((usageData.mau / usageData.tau) * 100).toFixed(2) : '0.00'}%</Text>
            </View>
            <View style={styles.smallContainer}>
                <Text style={styles.metricsText}>Weekly Active Users: {usageData.wau}</Text>
                <Text style={styles.metricsText}>WAU Rate: {usageData.tau > 0 ? ((usageData.wau / usageData.tau) * 100).toFixed(2) : '0.00'}%</Text>
            </View>
            <View style={styles.smallContainer}>
                <Text style={styles.metricsText}>Daily Active Users: {usageData.dau}</Text>
                <Text style={styles.metricsText}>DAU Rate: {usageData.tau > 0 ? ((usageData.dau / usageData.tau) * 100).toFixed(2) : '0.00'}%</Text>
            </View>
            <View style={styles.bottomCont}>
                <Text style={styles.smallText}>*Total Active Users is the total number of registered Indie Sub IDs we have on record for this app.</Text>
                <Text style={styles.smallText}>*Monthly Active Users is the total number of unique users who have opened your app in the past 30 days.</Text>
                <Text style={styles.smallText}>*Weekly Active Users is the total number of unique users who have opened your app in the past 7 days.</Text>
                <Text style={styles.smallText}>*Daily Active Users is the total number of unique users who have opened your app in the past 24 hours.</Text>
                <Text style={styles.smallText}>*There can be discrepancies in active users totals if you have released updated app builds within the past month. Each time a new build is released, your users are issued new Expo push tokens. We use these Expo push tokens as a way to identify your users. Each unique Expo push token counts as an active user.</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    HeadingTextTop: {
        fontFamily: "Arial",
        fontSize: 20,
        fontWeight: "700",
        marginBottom: 10,
        color: '#333',
    },
    plainText: {
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 20,
        color: '#555',
    },
    metricsContainer: {
        width: "100%",
        maxWidth: '80vw',
        marginTop: 20,
        padding: 10,
        paddingHorizontal: 20,
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#e5e5e5',
        alignItems: 'center',
    },
    metricsText: {
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 5,
        color: '#555',
    },
    bottomCont: {
        width: '100%',
        marginTop: 30,
        textAlign: 'left',
        gap: 10
    },
    smallText: {
        width: '100%',
        fontFamily: "Arial",
        fontSize: 12,
        color: '#555',
        textAlign: 'left'
    },
    smallContainer: {
        width: '100%',
        marginBottom: 10,
        textAlign: 'left'
    }
});
