import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import axios from '../../../../axios';

import DocsMenu from './Components/DocsMenu';

import { BiChevronRight } from 'react-icons/bi';

export default function StartHereDocs({ navigation, host, appInfo, setAppInfo, InAppNavigation }) {
    const { goToSend, setScreenName } = InAppNavigation;

    const [url, setUrl] = useState('');

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        setUrl(appInfo.website_to_app_url);
        window.scrollTo(0, 0);
    }, []);

    const sendNotification = () => {
        const date = new Date();
        let hour = date.getHours();
        let amPM = "";

        if (hour === 12) {
            amPM = "PM"
        } else if (hour > 12) {
            amPM = "PM"
            hour = hour - 12;
        } else {
            amPM = "AM"
        }

        axios
            .post(`${host}/api/notification`, { appId: getCookie('app_id'), title, body, dateSent: (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear() + " " + hour + ":" + ('0' + date.getMinutes()).slice(-2) + amPM })
            .then(res => {
                // console.log(res.data);
                alert(`Your push notification was successfully sent!`);
                setTitle('');
                setBody('');
            })
            .catch(err => {
                console.log(err);
                alert("Sorry, your app does not have any push notification subscribers yet. Try opening your app in the Expo Go app, then refresh this page and try again.");
                location.reload();
            })
    }

    const handleUpdateURL = (newURL) => {
        setUrl(newURL);
        axios
            .put(`${host}/api/web/to/app/url`, { appId: appInfo.app_id, appToken: appInfo.app_token, url: newURL })
            .then(res => setAppInfo(res.data))
            .catch(err => console.log(err));
    }

    return (
        <View style={styles.body}>
            {/* <DocsMenu InAppNavigation={InAppNavigation} /> */}

            <Text style={styles.HeadingText}>Option 1: Let us turn your website into an iOS app and/or an Android app for as little as $299</Text>
            <Text style={styles.mb}>If you want us to build your iOS app and/or Android app for you, visit this link: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://websitetoappconvert.com')}>https://websitetoappconvert.com</TouchableOpacity></Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>Option 2 (DIY): Follow these steps to turn your website into an Android and iOS app:</Text>
            <Text style={styles.plainText}>These instructions show how to turn your website into an app with push notifications, a push notification inbox, and analytics pre-installed.</Text>

            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/hKeVv0v6XaA?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                </View>
                : null
            }

            <View style={styles.dottedLine}></View>
            <Text style={styles.HeadingText}>Node installation guide:</Text>
            <Text style={styles.plainText}>You need 'node.js' installed on your computer before you can create your app.</Text>
            <Text style={styles.plainText}>
                If you have not yet installed 'node.js', go to this link to install 'node.js': <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://nodejs.org/en/download/')}>https://nodejs.org/en/download/</TouchableOpacity>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.HeadingText}>Code editor installation guide:</Text>
            <Text style={styles.plainText}>You need a code editor of some kind to edit your app code. We recommend using 'Visual Studio Code'. The instructions below assume you are using Visual Studio Code, but you can use any code editor you want and everything will still work.</Text>
            <Text style={styles.plainText}>
                If you have not yet installed a code editor, go to this link to install 'Visual Studio Code': <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://code.visualstudio.com/download')}>https://code.visualstudio.com/download</TouchableOpacity>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.HeadingText}>Create your app:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>Paste the URL to the website your want to turn into an app in the input below. Make sure to include 'https://' or 'http://' before the domain name:</Text>
            <TextInput
                style={styles.inputLarge}
                onChangeText={(text) => handleUpdateURL(text)}
                placeholder={"https://your-website-domain-name.com"}
                placeholderTextColor={"#6e6d6c"}
                value={url}
            />

            <Text style={styles.boldTextTop2}>Step 2:</Text>
            <Text style={styles.plainText}>Open up your computer terminal, and paste this code into the terminal and click "return/enter" to create your app:</Text>
            <Text style={styles.blackBacktext}>
                <Text>npx create-expo-app your-app-name</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Open up your new app folder/directory in Visual Studio Code.</Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Click "View" at the top of your Visual Studio Code screen, then click "Terminal" in the dropdown.</Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>In the newly opened terminal at the bottom of your Visual Studio Code screen, paste this code and click "return/enter":</Text>
            <Text style={styles.blackBacktext}>
                <Text style={styles.mbSmall}>npm install native-notify</Text>
                <Text>npx expo install expo-device expo-notifications expo-constants expo-constants @expo-google-fonts/open-sans axios react-native-webview react-native-vector-icons</Text>
            </Text>

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>Open the App.js or app/index.js or app/index.tsx file at the root of your new app folder in Visual Studio Code.</Text>

            <Text style={styles.boldText}>Step 7:</Text>
            <Text style={styles.plainText}>Delete all the code in the App.js or app/index.js or app/index.tsx file and replace it with the code below. We have already put your website URL, App ID, and App Token into the code for you. You don't need to change the code below. Just copy and paste the code below into your App.js file and everything will just work:</Text>
            <TextInput
                style={styles.inputBlackBack}
                multiline={true}
                numberOfLines={20}
                value={appDotJSText(url, appInfo.app_id, appInfo.app_token)} />

            <View style={styles.dottedLine}></View>

            {/* add in eas-cli installation and use to the setup instructions */}

            <Text style={styles.HeadingText}>See your app and send yourself a push notification:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>Type this into your Visual Studio Code terminal and click "return/enter" to install eas-cli on your computer:</Text>
            <Text style={styles.blackBacktext}>
                <Text>npm install --global eas-cli</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>Create a free Expo account here:</Text>
            <Text style={styles.blackBacktext}>
                <Text>https://expo.dev/signup</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Type this into your Visual Studio Code terminal and click "return/enter" to log into your Expo account:</Text>
            <Text style={styles.blackBacktext}>
                <Text>eas login</Text>
            </Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Type this into your Visual Studio Code terminal, click "return/enter", and answer "yes" to all questions to create a free EAS project:</Text>
            <Text style={styles.blackBacktext}>
                <Text>eas init</Text>
            </Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>In the terminal at the bottom of your Visual Studio Code screen, paste this code and click "return/enter":</Text>
            <Text style={styles.blackBacktext}>
                <Text>npx expo start</Text>
            </Text>

            {/* put updating the app.json file stuff in the publish your app page */}
            {/* goal of start here guide is to get user to see their app as fast as they can and send themself a push notification. */}

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>Open your app in the Expo Go app on your iOS phone or Android phone. If you have not downloaded the Expo Go app yet, here is the link: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://expo.dev/client')}>https://expo.dev/client</TouchableOpacity></Text>
            <Text style={styles.boldText}>You MUST open your app on an iOS phone or Android phone. Push notifications do NOT work in an Android emulator or iOS simulator.</Text>

            <Text style={styles.boldText}>Step 7: </Text>
            <Text style={styles.HeadingText}>Send a push notification to yourself:</Text>

            <View style={styles.sendCont}>
                <View style={styles.inputCont}>
                    <Text style={styles.text}>Notification Title:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setTitle(text)}
                        placeholder={"Type Notification Title here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={title}
                    />
                </View>
                <View style={styles.inputCont}>
                    <Text style={styles.text}>Notification Message:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setBody(text)}
                        placeholder={"Type Notification Message here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={body}
                        multiline={true}
                    />
                </View>

                <TouchableOpacity style={styles.sendButton} onPress={() => sendNotification()}>
                    <Text style={styles.buttonText}>Send Push Notification</Text>
                </TouchableOpacity>
            </View>

            <View style={styles.dottedLine}></View>
            <Text style={styles.HeadingText}>What now?</Text>

            <Text style={styles.boldText}>Publish an Android App:</Text>
            <Text style={styles.plainText}>
                Go to this link to create a Google Play store developer account if you want to publish an Android app: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://play.google.com/console/u/0/signup')}>https://play.google.com/console/u/0/signup</TouchableOpacity>
            </Text>
            <Text style={styles.boldText}>Publish an iOS App:</Text>
            <Text style={styles.mb}>
                Go to this link to create a Apple App Store developer account if you want to publish an iOS app: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://developer.apple.com/programs/enroll/')}>https://developer.apple.com/programs/enroll/</TouchableOpacity>
            </Text>
            {/* <Text style={styles.plainText}>
                After you have created your Apple App Store developer account, you will need to download the Apple Transporter App to upload your app to your Apple App Store developer account. You can download the Transporter app here: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://apps.apple.com/us/app/transporter/id1450874784?mt=12')}>https://apps.apple.com/us/app/transporter/id1450874784?mt=12</TouchableOpacity>
            </Text> */}

            <Text style={styles.boldText}>Then go to this link and click on the "Docs" menu to see further iOS and Android production setup instructions:</Text>

            <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('InApp')}>
                <Text style={styles.buttonText}>Go to your Native Notify app <BiChevronRight /></Text>
            </TouchableOpacity>

        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },
    purpleBack: {
        backgroundColor: '#3675d420',
        padding: screen.width > 900 ? 15 : 10
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
        display: 'flex',
        alignItems: "center"
    },
    button: {
        width: 'fit-content',
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        paddingHorizontal: 15,
        marginTop: 10,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    boldTextTop2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 10,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: 'rgb(81, 167, 81)'
    },
    sendCont: {
        padding: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 5,
        // marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    inputBlackBack: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    }
});


const appDotJSText = (url, appID, appToken) => {
    return (
        "import React, { useState, useEffect } from 'react'; \n" +
        "import { StyleSheet, Text, View, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native'; \n" +
        "import { WebView } from 'react-native-webview'; \n" +
        "import Constants from 'expo-constants'; \n" +
        "import axios from 'axios'; \n" +
        " \n" +
        "import registerNNPushToken, { getPushDataObject, getNotificationInbox, getUnreadNotificationInboxCount } from 'native-notify'; \n" +
        "import { useFonts, OpenSans_300Light, OpenSans_600SemiBold } from '@expo-google-fonts/open-sans'; \n" +
        "import HomeIcon from 'react-native-vector-icons/Ionicons'; \n" +
        "import InboxIcon from 'react-native-vector-icons/Ionicons'; \n" +
        " \n" +
        "export default function App() { \n" +
        `  const [url, setUrl] = useState('${url}'); \n` +
        "  const [webKey, setWebKey] = useState(1); \n" +
        "  const [notInboxData, setNotInboxData] = useState([]); \n" +
        "  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0); \n" +
        " \n" +
        "  const [visible, setVisible] = useState(false); \n" +
        "  const [screenName, setScreenName] = useState('Home'); \n" +
        " \n" +
        `  registerNNPushToken(${appID}, '${appToken}'); \n` +
        "  let pushDataObject = getPushDataObject(); \n" +
        " \n" +
        "  useFonts({ OpenSans_300Light, OpenSans_600SemiBold }); \n" +
        "  \n" +
        "  useEffect(() => { \n" +
        "    async function getUnreadNots() { \n" +
        `      let unreadCount = await getUnreadNotificationInboxCount(${appID}, '${appToken}'); \n` +
        "      setUnreadNotificationCount(unreadCount); \n" +
        "    } \n" +
        "    getUnreadNots(); \n" +
        "  }, []); \n" +
        "  \n" +
        "  useEffect(() => { \n" +
        "    if('newURL' in pushDataObject) { \n" +
        "      setUrl(pushDataObject.newURL); \n" +
        "    } \n" +
        "  }, [pushDataObject]) \n" +
        " \n" +
        "  const ActivityIndicatorElement = () => { \n" +
        "    return ( \n" +
        "      <View style={styles.activityIndicatorStyle}> \n" +
        "        <ActivityIndicator color='#009688' size='large' /> \n" +
        "      </View> \n" +
        "    ); \n" +
        "  }; \n" +
        "  \n" +
        "  const handleGoToInbox = async () => { \n" +
        `    let notifications = await getNotificationInbox(${appID}, '${appToken}'); \n` +
        "    setNotInboxData(notifications); \n" +
        "    setScreenName('NotificationInbox'); \n" +
        "    setUnreadNotificationCount(0); \n" +
        "  } \n" +
        " \n" +
        "  return ( \n" +
        "    <View style={styles.page}> \n" +
        "      {screenName === 'Home' \n" +
        "        ? <View style={styles.body}>  \n" +
        "            <WebView  \n" +
        "              key={webKey} \n" +
        "              style={styles.webview} \n" +
        "              source={{ uri: url }} \n" +
        "              onLoadStart={() => setVisible(true)} \n" +
        "              onLoad={() => setVisible(false)} \n" +
        "            /> \n" +
        "            {visible ? <ActivityIndicatorElement /> : null} \n" +
        "          </View> \n" +
        "        : null}  \n" +
        "      {screenName === 'NotificationInbox' \n" +
        "        ? <View style={styles.body}> \n" +
        "            <FlatList \n" +
        "                data={notInboxData} \n" +
        "                keyExtractor={item => item.notification_id} \n" +
        "                renderItem={({ item }) => { \n" +
        "                    return ( \n" +
        "                        <View style={styles.notInboxCont}> \n" +
        "                            <Text style={styles.title}>{item.title}</Text> \n" +
        "                            <Text style={styles.messageText} >{item.message}</Text> \n" +
        "                            <Text style={styles.dateText} >{item.date}</Text> \n" +
        "                        </View> \n" +
        "                    ) \n" +
        "                }} \n" +
        "            /> \n" +
        "          </View> \n" +
        "        : null}  \n" +
        "      <View style={styles.footer}> \n" +
        "        <HomeIcon  \n" +
        "          name={screenName === 'Home' ? 'home' : 'home-outline'}  \n" +
        "          size={30}  \n" +
        "          color={'#141414'}  \n" +
        "          onPress={() => { setScreenName('Home'); setWebKey(webKey + 1); }}/> \n" +
        "        <TouchableOpacity style={styles.icon} onPress={() => handleGoToInbox()}> \n" +
        "          <InboxIcon  \n" +
        "            name={screenName === 'NotificationInbox' ? 'md-mail' : 'md-mail-outline'}  \n" +
        "            size={30}  \n" +
        "            color={'#141414'} />     \n" +
        " \n" +
        "          {unreadNotificationCount  \n" +
        "              ? <View style={styles.redEmptyBubble}></View>  \n" +
        "              : null} \n" +
        "        </TouchableOpacity> \n" +
        "      </View> \n" +
        "    </View> \n" +
        "  ) \n" +
        "} \n" +
        "\n" +
        "const styles = StyleSheet.create({ \n" +
        "  page: { \n" +
        "    flex: 1,  \n" +
        "    height: '100%', \n" +
        "    width: '100%', \n" +
        "    backgroundColor: 'transparent', \n" +
        "    paddingTop: Constants.statusBarHeight \n" +
        "  }, \n" +
        "  body: { \n" +
        "    flex: 9, \n" +
        "    width: '100%', \n" +
        "  }, \n" +
        "  footer: { \n" +
        "    flex: 1, \n" +
        "    width: '100%', \n" +
        "    flexDirection: 'row', \n" +
        "    justifyContent: 'space-around', \n" +
        "    alignItems: 'center', \n" +
        "    backgroundColor: '#fff', \n" +
        "    borderTopWidth: 1, \n" +
        "    borderTopColor: '#00000020', \n" +
        "    shadowColor: '#000', \n" +
        "    shadowOffset: { \n" +
        "      width: 0, \n" +
        "      height: 2, \n" +
        "    }, \n" +
        "    shadowOpacity: 0.25, \n" +
        "    shadowRadius: 3.84, \n" +
        "    elevation: 5, \n" +
        "  }, \n" +
        "  \n" +
        "  // webview \n" +
        "  webview: { \n" +
        "    flex: 1, \n" +
        "    alignItems: 'center', \n" +
        "    justifyContent: 'center' \n" +
        "  }, \n" +
        "  activityIndicatorStyle: { \n" +
        "    flex: 1, \n" +
        "    position: 'absolute', \n" +
        "    marginLeft: 'auto', \n" +
        "    marginRight: 'auto', \n" +
        "    marginTop: 'auto', \n" +
        "    marginBottom: 'auto', \n" +
        "    left: 0, \n" +
        "    right: 0, \n" +
        "    top: 0, \n" +
        "    bottom: 0, \n" +
        "    justifyContent: 'center', \n" +
        "  }, \n" +
        "  \n" +
        "  // notification inbox \n" +
        "  notInboxCont: { \n" +
        "    width: '100%', \n" +
        "    padding: 15, \n" +
        "    backgroundColor: '#fff', \n" +
        "    borderWidth: 0.75, \n" +
        "    borderColor: '#d8d8d8', \n" +
        "  }, \n" +
        "  title: { \n" +
        "      width: '90%', \n" +
        "      fontFamily: 'OpenSans_600SemiBold', \n" +
        "      marginBottom: 5, \n" +
        "      fontSize: 14, \n" +
        "  }, \n" +
        "  messageText: { \n" +
        "      fontFamily: 'OpenSans_300Light', \n" +
        "      marginTop: 2, \n" +
        "      fontSize: 14, \n" +
        "      marginTop: 5 \n" +
        "  }, \n" +
        "  dateText: { \n" +
        "      fontFamily: 'OpenSans_300Light', \n" +
        "      marginTop: 2, \n" +
        "      fontSize: 14, \n" +
        "      marginTop: 5, \n" +
        "      textAlign: 'right' \n" +
        "  }, \n" +
        "  icon: { \n" +
        "      flexDirection: 'row' \n" +
        "  }, \n" +
        "  redEmptyBubble: { \n" +
        "    height: 14, \n" +
        "    width: 14, \n" +
        "    padding: 1, \n" +
        "    backgroundColor: 'rgb(228, 66, 88)', \n" +
        "    borderRadius: 12, \n" +
        "    position: 'absolute', \n" +
        "    right: -5, \n" +
        "    zIndex: 5 \n" +
        "  } \n" +
        "}); \n"
    )
}