import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, ScrollView, StyleSheet, Image, Text, TouchableOpacity } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';

import Header from '../../Headers/LoggedInHeader';

export default function FAQ({ navigation, setAppInfo, userInfo }) {
    return (
        <View style={styles.page}>
            <Header 
                navigation={navigation} 
                setAppInfo={setAppInfo} 
                userInfo={userInfo}
            />
            <Text style={styles.h1Top}>Got Questions?</Text>
            <Text style={styles.par}>If you have any questions or concerns, please email us at this address: <Text style={styles.blueText}>support@nativenotify.com</Text></Text>
            <Text style={styles.par}>We will usually respond within 3 business days.</Text>

            {/* <View style={styles.pricingFAQCont}>
                <Text style={styles.pBoldLarge}>FAQs</Text>
                <Text style={styles.pBold}>My push notification icon is all white or all grey. How do I fix that?</Text>
                <Text style={styles.p}>To fix this, your push notification icon must be a 96x96 png with a transparent background and an all white image. Then put this "plugins" code in the "expo" part of your app.json file:</Text>
                <Text style={styles.par}></Text>
                <Text style={styles.p}>"expo": {'{'}</Text>
                <Text style={styles.p}>   "plugins": [</Text>
                <Text style={styles.p}>      [</Text>
                <Text style={styles.p}>         "expo-notifications",</Text>
                <Text style={styles.p}>         {'{'}</Text>
                <Text style={styles.p}>             "icon": "./local/assets/notification-icon.png",</Text>
                <Text style={styles.p}>             "color": "#ffffff"</Text>
                <Text style={styles.p}>         {'}'}</Text>
                <Text style={styles.p}>      ]</Text>
                <Text style={styles.p}>   ]</Text>
                <Text style={styles.p}>{'}'}</Text>
                <Text style={styles.par}></Text>
                <Text style={styles.p}>In the "color" part, you can change the color of the image from white to whatever color you want.</Text>
                <Text style={styles.p}>For the above code to work, you should name your notification icon 'notification-icon.png' and put it in the 'assets' folder in the root of your project.</Text>


                <Text style={styles.pBoldLarge}>Pricing FAQs</Text>
                <Text style={styles.pBold}>Is there a free trial period?</Text>
                <Text style={styles.p}>Yes. There is a 7-day free trial period. During your 7-day free trial period, you can use all of our services for free.</Text>
                <Text style={styles.pBold}>Is there a free Native Notify plan?</Text>
                <Text style={styles.p}>Yes. Native Notify is free to use up to 10 push notification subscribers.</Text>
                <Text style={styles.pBold}>How much does Native Notify cost after the free trial period once I have more than 10 push notification subscribers?</Text>
                <Text style={styles.p}>Here's how our pricing works:</Text>
                <Text style={styles.p}>     - Native Notify is free up to 10 push notification subscribers.</Text>
                <Text style={styles.p}>     - 11-10,000 push notification subscribers is $99/month.</Text>
                <Text style={styles.p}>     - Then it's $50/month extra per 10,000 additional push notification subscribers.</Text>
                <Text style={styles.pBold}>Do you accept foreign currency?</Text>
                <Text style={styles.p}>Yes, we accept foreign currency. You can pay with a credit/debit card from your home country and we will automatically convert them to USD for you.</Text>

                <Text style={styles.pBold}>What is considered a push notification subscriber?</Text>
                <Text style={styles.p}>The following is considered a push notification subscriber:</Text>
                <Text style={styles.p}>   - a unique device that has successfully received one of your push notifications</Text>
                <Text style={styles.p}>   - an Indie Push Notification subscriber</Text>
                <Text style={styles.p}>   - a Follow Push Notification subscriber</Text>
                <Text style={styles.p}>   - a Topic Group subscriber</Text>
            </View> */}
        </View>

    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        height: '100%',
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: '#fff',
    },

    h1Top: {
        fontSize: 24,
        fontFamily: 'Arial',
        fontWeight: 700,
        marginTop: 60,
        marginBottom: 20,
        marginLeft: '5%',
        marginRight: '5%',
    },
    h1: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: 60,
        marginBottom: 30,
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    par: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    pBold: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 700,
        marginTop: 20,
        marginLeft: '5%',
        marginRight: '5%',
    },
    p: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginLeft: '5%',
        marginRight: '5%',
    },
    pBoldLarge: {
        fontSize: 24,
        fontFamily: 'Arial',
        fontWeight: 700,
        marginTop: 20,
        marginLeft: '5%',
        marginRight: '5%',
    },
    pricingFAQCont: {
        backgroundColor: 'rgba(53, 118, 212, 0.125)',
        marginTop: 60,
        paddingTop: '4%',
        paddingBottom: '4%',
    }
});