import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, ScrollView, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from '../../axios';

import { FaArrowRightLong } from "react-icons/fa6";

import Header from '../../Headers/LoggedInHeader';

import ChooseAFramework from '../ChooseAFramework/ChooseAFramework';
import UpdatePlan from '../Components/UpdatePlan';

export default function Dashboard({ navigation, host, userInfo, setUserInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [showLoading, setShowLoading] = useState(true);
    const [apps, setApps] = useState([]);
    const [showProScreen, setShowProScreen] = useState(false);
    const [email, setEmail] = useState("true");
    const subName = userInfo.sub_name
    const subNameString = 
        subName === 1 ? "Basic" :
        subName === 2 ? "Premium" :
        subName === 3 ? "Pro" 
        : "Free Trial";

    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        axios
            .get(`${host}/api/all/apps`)
            .then(res => {
                // console.log("all apps: ", res.data)
                setApps(res.data);
                // console.log("res.data: ", res.data)

                axios
                    .get(`${host}/api/user`)
                    .then(response => {
                        const data = response.data;
                        setUserInfo(response.data);
                        // console.log(data);

                        setEmail(response.data.email);

                        if (!data.paid_sub) {
                            // check session to see if payment was successful
                            checkSession(data)
                        } else if (data.paid_sub && !data.custom_pricing) {
                            axios
                                .put(`${host}/api/stripe/subscription/quantity`, {})
                            // .then(responseToPost => console.log(responseToPost))
                            // .catch(errorOfPost => console.log(errorOfPost))
                            setShowLoading(false);
                        } else if (data.paid_sub && data.custom_pricing) {
                            setShowLoading(false);
                        }
                    })
                    .catch(err => console.log(err))
            })
            .catch(err => {
                console.log(err);
                setShowLoading(false);
            })
    }, [appUpdated]);


    const openExpoApp = (appInfo) => {
        // console.log("openExpoApp appInfo: ", appInfo)
        setAppInfo(appInfo);
        document.cookie = `app_id = ${appInfo.app_id}`;
        setAppUpdated(appInfo.app_id);
        if (getCookie('app_id') == appInfo.app_id) {
            navigation.navigate('InApp');
        }
    }

    const checkSession = (data) => {
        // console.log("checkSession fired: ", data)
        axios
            .post(`${host}/api/stripe/check/session`, {
                pushSubCount: userInfo.push_sub_count,
                appCount: userInfo.number_of_apps_in_account
            })
            .then(res => {
                if (res.data === "fail") {
                    let today = new Date();
                    let priorDate = new Date().setDate(today.getDate() - 7);

                    if (
                        +new Date(priorDate) > +new Date(data.date_signed_up) && 
                        !data.custom_pricing &&
                        data.mau > 5
                    ) {
                        setShowProScreen(true);
                        setShowLoading(false);
                    }
                            
                        setShowLoading(false);
                    } else {
                        setShowProScreen(false);
                        setShowLoading(false);
                    }
            })
            .catch(err => {
                console.log(err);
                setShowLoading(false);
            })
    }

    if (showLoading) {
        return (
            <View style={styles.page}>
                <View style={styles.centerScreen}>
                    <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
                </View>
            </View>
        )
    }

    return (
        <View style={styles.page}>
            <Header 
                navigation={navigation} 
                setAppInfo={setAppInfo} 
                userInfo={userInfo}
            />
            {showProScreen
                ? <ScrollView
                    ref={ref}
                    style={styles.scrollViewContPro}
                    contentContainerStyle={styles.scrollView}
                >
                    <View style={styles.container}>
                        <View style={styles.goProCont}>
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6, marginBottom: 5 }}>
                                <Image
                                    style={styles.logo}
                                    source={require('../../assets/images/logo.png')}
                                    resizeMode='cover'
                                />
                                <Text style={{ fontSize: 24, fontWeight: 700, fontFamily: 'Arial' }}>Your free trial has ended.</Text>
                            </View>

                            <Text style={styles.subHeader}>Your 7-day free trial has ended and you have more than 5 monthly active users. You must upgrade your plan to continue using Native Notify.</Text>
                        </View>

                        <UpdatePlan 
                            host={host} 
                            hideFreePlan={true} 
                            subNameString={subNameString}
                            setAppUpdated={setAppUpdated}
                        />
                    </View>
                </ScrollView>
                : <ScrollView
                    ref={ref}
                    style={styles.scrollViewCont}
                    contentContainerStyle={styles.scrollView}
                >
                    <ChooseAFramework
                        navigation={navigation}
                        host={host}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        setAppInfo={setAppInfo}
                        appUpdated={appUpdated}
                        setAppUpdated={setAppUpdated}
                    />
                    <View style={styles.container}>
                        <View className style={styles.flexRow}>
                            <Text style={styles.pBold}>Your apps:</Text>
                            {apps
                                ? apps.map((e, i) => {
                                    return (
                                        <TouchableOpacity key={i} style={styles.appCont} onPress={() => openExpoApp(e)}>
                                            <Text style={styles.appTitle}>{e.app_name}</Text>
                                            <FaArrowRightLong />
                                        </TouchableOpacity>
                                    )
                                })
                                : null
                            }
                        </View>
                    </View>
                </ScrollView>
            }
        </View>
    )
}

let flexRowJustify, appMR, appWH, pagePadding;

if (screen.width < 500) {
    flexRowJustify = 'space-between';
    appMR = 0;
    pagePadding = 15;
} else {
    flexRowJustify = 'flex-start';
    appMR = 30;
    pagePadding = 30;
}

if (screen.width > 500) {
    appWH = '20vw';
} else { 
    appWH = '41vw' 
}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        backgroundColor: "#fafafa",
        alignItems: 'center',
    },
    scrollViewCont: {
        height: '100%',
        width: '100%',
        padding: pagePadding,
        paddingTop: 0
    },
    scrollViewContPro: {
        height: '100%',
        width: '100%',
        backgroundColor: "#eceef2",
    },
    scrollView: {
        height: '100%',
        width: '100%',
        // padding: '5%',
        // paddingTop: 30,
    },
    container: {
        width: '100%',
        alignItems: 'center',
        paddingBottom: 100,
        paddingHorizontal: '5%',
    },
    flexRow: {
        width: '100%',
        maxWidth: 1200,
        gap: 20
    },
    maxWidth: {
        width: '100%',
        maxWidth: 1200,
    },
    centerScreen: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },


    goProCont: {
        justifyContent: 'center',
        // alignItems: 'center',
        padding: 15,
        marginBottom: 15,
        // textAlign: 'center',
        backgroundColor: '#ff9d9d',
        // boxShadow: '0px 1px 3px #6b6b6b',
        borderRadius: '6px',
        maxWidth: '90vw',
        marginTop: 30,
        width: 'content-fit',
        maxWidth: 1200
    },

    h1: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 10,
        // textAlign: 'center'
    },
    subHeader: {
        fontFamily: 'Arial',
        fontWeight: 500,
        // color: "white",
        fontSize: 18,
        marginBottom: 10,
        maxWidth: 800
    },
    p: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginBottom: 5,
    },
    pSmall: {
        fontSize: 12,
        fontFamily: 'Arial',
        fontWeight: 400,
        color: "#6b6b6b",
    },
    pBold: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 700,
        marginTop: 20
    },

    logo: {
        width: 24,
        height: 24
    },
    goPremiumButton: {
        width: 'fit-content',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 20,
        marginBottom: 10,
        backgroundColor: '#2690cb',
        color: 'white',
        border: '0',
        borderRadius: '6px',
        boxShadow: '0px 1px 2px #26272b',
        fontSize: '20px',
        fontWeight: '700',
        cursor: 'pointer'
    },
    createNotificationButton: {
        width: 220,
        backgroundColor: '#e54b4d',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 5,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 1px 2px #26272b',
        marginBottom: 30
    },
    createNotificationText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 20,
        color: '#fff',
        marginLeft: 10,
        marginRight: 3
    },
    openButton: {
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 3,
        backgroundColor: '#3568b3',
        color: 'white',
        fontWeight: 700
    },
    icon: {
        paddingTop: 2,
        marginLeft: -1
    },

    appCont: {
        height: 60,
        width: '100%',
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 8,
        boxShadow: '0 1px 2px 0 #d6d6d6',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        backgroundColor: '#fff',
    },
    appTitle: {
        fontFamily: 'Arial',
        fontWeight: 600,
        padding: 15,
        borderRadius: 6,
    },
    mb: {
        marginBottom: 60
    },

    pricingContainer: {
        width: '100%',
        maxWidth: 1200,
        marginTop: 30,
        marginBottom: 30,
        alignItems: 'center',
    },
    pricingTable: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 5,
        overflow: 'hidden',
        marginBottom: 30,
        backgroundColor: 'white',
    },
    pricingRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    pricingCell: {
        flex: 1,
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    pricingHeader: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        fontSize: 18,
    },
    includedFeatures: {
        width: '100%',
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 5,
    },
    featureRow: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    checkIcon: {
        marginRight: 15,
    },
    featureItem: {
        flex: 1,
        fontSize: 18,
        fontFamily: 'Arial',
        lineHeight: 26,
        color: '#333',
    },
    faqContainer: {
        width: '100%',
        maxWidth: 800,
        marginTop: 20,
    },
    faqItem: {
        marginBottom: 20,
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    faqQuestion: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#2690cb',
    },
    faqAnswer: {
        fontSize: 18,
        fontFamily: 'Arial',
        marginBottom: 5,
        lineHeight: 26,
        color: '#333',
    },
});