import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  TextInput,
} from 'react-native';
import axios from 'axios';

import Header from '../../../Headers/HomeHeader';

export default function SignUpOne({
  navigation,
  host,
  setUserInfo,
  setValidationCode,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePost = async () => {
    setUserInfo({ name, email, password });

    if (name && email && password) {
      setLoading(true);

      await axios
        .post(`${host}/api/auth/register`, {
          name: name,
          email: email,
          password: password,
        }, { withCredentials: true })
        .then((res) => {
          if (res.data === 'Email in use.') {
            alert(
              'Sorry, that email is already associated with a Native Notify account. Please try again with another email or login.'
            );
            setLoading(false);
          } else {
            navigation.navigate('Dashboard');
          }
        })
        .catch((err) => {
          console.log(err);
          alert('Sorry. Our servers seem to be down. Please try again later.');
          setLoading(false);
        });
    } else {
      alert(
        'Please provide a Name, Email, and Password before clicking the "Sign Up Free" button.'
      );
    }
  };

  return (
    <div style={styles.page} className='radialGradientBackground'>
      <View style={styles.bodyContainer}>
        <Header navigation={navigation} />
        <View style={styles.body}>
          <Text style={styles.headerText}>Sign Up Free</Text>
          <Text style={styles.subtitleText}>No credit card required</Text>
          <TextInput
            style={styles.inputLarge}
            onChangeText={(text) => setName(text)}
            placeholder={'Name'}
            placeholderTextColor={'#6e6d6c'}
            value={name}
          />
          <TextInput
            style={styles.inputLarge}
            onChangeText={(text) => setEmail(text.toLowerCase())}
            placeholder={'Email'}
            placeholderTextColor={'#6e6d6c'}
            value={email}
          />
          <TextInput
            style={styles.inputLarge}
            onChangeText={(text) => setPassword(text)}
            placeholder={'Password'}
            placeholderTextColor={'#6e6d6c'}
            value={password}
            secureTextEntry={true}
          />

          <View style={styles.signUpCont}>
            <TouchableOpacity
              style={loading ? styles.buttonLoading : styles.button}
              onPress={() => handlePost()}
              disabled={loading}
            >
              {loading ? (
                <ActivityIndicator size="small" color="rgb(54, 117, 212)" />
              ) : (
                <Text style={styles.text}>Sign Up Free</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: 'rgb(20, 20, 60)'
  },
  bodyContainer: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
    height: '100%'
  },
  radialGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  body: {
    width: '95%',
    maxWidth: 500,
    maxHeight: 700,
    padding: '3%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 6,
    marginTop: 60,
    alignSelf: 'center',
  },
  headerText: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Arial',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 5,
  },
  subtitleText: {
    width: '100%',
    fontFamily: 'Arial',
    fontWeight: '400',
    color: '#6e6d6c',
    marginBottom: 20,
  },
  inputLarge: {
    width: '100%',
    padding: 12.5,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: '#99999920',
    backgroundColor: '#fff',
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginBottom: 15,
  },
  signUpCont: {
    width: '100%',
    alignItems: 'center',
    marginTop: 25,
  },
  button: {
    width: 220,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#06bd43',
    borderRadius: 6,
    marginBottom: 20,
  },
  buttonLoading: {
    width: 220,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#06bd4340',
    borderRadius: 6,
    marginBottom: 20,
  },
  text: {
    fontFamily: 'Arial',
    fontWeight: '700',
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
  },
});
