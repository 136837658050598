import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import axios from '../../../../../axios'; // Adjust the path as needed
import { LineChart } from 'react-native-chart-kit';
import { useWindowDimensions } from 'react-native';

export default function ViewsOverview({ host, appInfo, period = 'month' }) {
    const [dailyAnalytics, setDailyAnalytics] = useState([]);
    const scrollRef = useRef();

    const { width } = useWindowDimensions();
    const isDesktop = width > 800; // Adjust breakpoint as needed

    // Determine the number of days based on the 'period' prop
    const days = period === 'week' ? 6 : 29;

    // Fetch daily analytics data
    useEffect(() => {
        async function fetchDailyAnalytics() {
            try {
                const response = await axios.get(`${host}/api/views/${appInfo.app_id}/${appInfo.app_token}?days=${days}`);
                // console.log("response.data: ", response.data)
                if (response.data) {
                    setDailyAnalytics(response.data); 
                }
            } catch (err) {
                console.log("Error fetching daily analytics: ", err);
            }
        }

        if (appInfo) {
            fetchDailyAnalytics();
        }
        scrollRef.current?.scrollTo({ y: 0, animated: false });
    }, [host, appInfo, days]);

    

    // Prepare chart data
    const prepareChartData = () => {
        if (dailyAnalytics.length === 0) return null;

        const startMonth = new Date(dailyAnalytics[0].day).toLocaleString('en-US', { month: 'numeric', timeZone: 'UTC' });
        let differentEndMonth = false;

        const labels = dailyAnalytics.map((item, index) => {
            const date = new Date(item.day);
            const month = date.toLocaleString('en-US', { month: 'numeric', timeZone: 'UTC' });
            const day = date.toLocaleString('en-US', { day: 'numeric', timeZone: 'UTC' });

            if (period === 'week') {
                if (index === 0) {
                    return `${startMonth}/${day}`;
                } else if (startMonth !== month) {
                    return `${month}/${day}`;
                } else {
                    return `${day}`;
                }
            } else if (period === 'month') {
                if (isDesktop) {
                    if (index === 0) {
                        return `${startMonth}/${day}`;
                    } else if (startMonth !== month && !differentEndMonth) {
                        differentEndMonth = true;
                        return `${month}/${day}`;
                    } else {
                        return `${day}`;
                    }
                } else {
                    if (isDesktop) {
                        if (index === 0 || index === 9 || index === 19 || index === 29) {
                            return `${month}/${day}`;
                        } else {
                            return "";
                        }
                    } else {
                        if (index === 0 || index === 8 || index === 17 || index === 25) {
                            return `${month}/${day}`;
                        } else {
                            return "";
                        }
                    }
                }
            }
        });

        const totalViews = dailyAnalytics.map(item => Number(item.total_views) || 0);
        const uniqueViews = dailyAnalytics.map(item => Number(item.unique_views) || 0);

        return {
            labels,
            datasets: [
                {
                    data: totalViews,
                    color: (opacity = 1) => `rgba(54,117,212, ${opacity})`,
                    strokeWidth: 2,
                    label: 'Total Views',
                },
                {
                    data: uniqueViews,
                    color: (opacity = 1) => `rgba(75,192,192, ${opacity})`,
                    strokeWidth: 2,
                    label: 'Unique Views',
                },
            ],
            legend: ['Total Views', 'Unique Views'],
        };
    };

    const chartData = prepareChartData();
    const chartWidth = isDesktop 
        ? period === 'month' 
            ? width * 0.80
            : width * 0.35 
        : width * 0.71;

    return (
        <ScrollView style={styles.container} ref={scrollRef}>
            <Text style={styles.heading}>
                {period === 'week' ? 'Past 7 Days Views' : 'Past 30 Days Views'}
            </Text>

            <View style={{ marginVertical: 20 }}>
                {chartData ? (
                    <>
                        <View style={styles.chartWrapper}>
                            <LineChart
                                data={chartData}
                                width={chartWidth}
                                height={220}
                                fromZero={true}
                                decimalPlaces={0}
                                chartConfig={{
                                    backgroundColor: '#fff',
                                    backgroundGradientFrom: '#f5f5f5',
                                    backgroundGradientTo: '#e6e6e6',
                                    decimalPlaces: 0,
                                    color: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                                    labelColor: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
                                    style: {
                                        borderRadius: 16,
                                        fontFamily: "Arial",
                                        paddingHorizontal: 0
                                    },
                                    propsForDots: {
                                        r: "4",
                                        strokeWidth: "1",
                                        stroke: "#3675d2",
                                    },
                                    propsForBackgroundLines: {
                                        stroke: "#e3e3e3",
                                    },
                                }}
                                bezier
                                style={{
                                    marginVertical: 8,
                                    borderRadius: 16,
                                    fontFamily: "Arial",
                                }}
                            />
                        </View>

                        <Text style={styles.smallText}>
                            *Total Views are the total number of times your app was opened.
                        </Text>
                        <Text style={styles.smallText}>
                            *Unique Views are the total number of times a unique user opened your app.
                        </Text>

                        <View style={styles.analyticsContainer}>
                            {dailyAnalytics.slice().reverse().map((item, index) => (
                                <View key={index} style={styles.analyticsRow}>
                                    <Text style={styles.dateText}>
                                        {new Date(item.day).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                    </Text>
                                    <Text style={styles.totalViewsText}>
                                        Total Views: {item.total_views}
                                    </Text>
                                    <Text style={styles.uniqueViewsText}>
                                        Unique Views: {item.unique_views}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </>
                ) : (
                    <Text style={styles.noDataText}>No data available for the selected period.</Text>
                )}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        marginTop: 20,
        padding: 10,
        paddingHorizontal: 20,
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#e5e5e5',
    },
    heading: {
        fontFamily: "Arial",
        fontSize: 20,
        fontWeight: "700",
        marginBottom: 10,
        color: '#333',
        textAlign: 'center',
    },
    chartWrapper: {
        alignItems: 'center',
    },
    analyticsContainer: {
        marginTop: 20,
        paddingHorizontal: 10,
        paddingVertical: 15,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    analyticsRow: {
        flexDirection: 'column',
        marginBottom: 10,
        padding: 10,
        backgroundColor: '#fff',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#e5e5e5',
    },
    dateText: {
        fontFamily: "Arial",
        fontSize: 16,
        fontWeight: "600",
        color: '#333',
        marginBottom: 5,
    },
    totalViewsText: {
        fontFamily: "Arial",
        fontSize: 14,
        color: '#555',
    },
    uniqueViewsText: {
        fontFamily: "Arial",
        fontSize: 14,
        color: '#555',
    },
    noDataText: {
        fontFamily: "Arial",
        fontSize: 16,
        color: '#777',
        textAlign: 'center',
    },
    bottomText: {
        fontFamily: "Arial",
        fontSize: 12,
        marginTop: 30,
        color: '#555',
    },
    smallText: {
        fontFamily: "Arial",
        fontSize: 12,
        marginTop: 5,
        color: '#555',
    },
});
